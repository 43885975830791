import React from "react";

export const Spinner = ()=><>
  <style>{`
  .lds-ripple {
    width: 80px;
    height: 80px;
    background: transparent;
    position: fixed;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    z-index: 999;
    
}
  .lds-ripple div {
    position: absolute;
    border: 4px solid #ebe400;
    opacity: 1;
    border-radius: 50%;
    animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
    background: white;
}
  .lds-ripple div:nth-child(2) {
    animation-delay: -0.5s;
  }
  @keyframes lds-ripple {
    0% {
      top: 36px;
      left: 36px;
      width: 0;
      height: 0;
      opacity: 1;
    }
    100% {
      top: 0px;
      left: 0px;
      width: 72px;
      height: 72px;
      opacity: 0;
    }
  }
  `}</style>
  <div className="lds-ripple"><div></div><div></div></div>
</>; 

export default Spinner;
