import React, { lazy, Suspense, useEffect } from "react";
import {
  HashRouter as Router,
    Routes,
    Route,
    Link,
    useParams
  } from "react-router-dom";
import { Spinner } from './Components/Spinner';
import { getGlobalStore } from "./store";
import { getUserStatus } from "./api";


/**
 * react lazy is used to enable code slipping and thus app loading will be faster
 ***/
const Header = lazy(() => import('./Components/Header')); 
const Nav = lazy(() => import('./Components/Nav'));
const Banner = lazy(() => import('./Components/Banner'));
const Footer = lazy(() => import('./Components/Footer'));
const HomeMain = lazy(()=>import('./Components/HomeMain'));
const LoginPage = lazy(()=>import('./Components/LoginPage'));
const Dashboard = lazy(()=>import('./Components/Dashboard'));

const App = () => {

  const {loggedIn, login, updateUser} = getGlobalStore();


const loadUserData = () => {
  getUserStatus().then(data => {
    console.log(data);
    updateUser(data);
  })
}

  useEffect(()=>{
    const isLoggedIn = sessionStorage.getItem('isLoggedIn');
    if(isLoggedIn){
      login();
    }
  }, []);

  useEffect(()=> {
    loggedIn && loadUserData();
  }, [loggedIn])

    return (
      <Suspense fallback={<Spinner />}>
        <Router>
          <Banner
            message="THIS WEBSITE IS ONLY FOR TRAINING AND PRACTICE"
            type="warning"
          >
          </Banner>
          <Header />
          <Nav />
          <Suspense fallback={<Spinner />}>
          <Routes>
            <Route path="/" element={<HomeMain />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/dashboard/*" element={<Dashboard />} />
          </Routes>
          </Suspense>
          <Footer />
        </Router>
      </Suspense>
    );
}

export default App;