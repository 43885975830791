import React from 'react';
import ReactDOM from "react-dom";
import App from './App';


/* TODO: remove this dirty hack to prevent input[type=number] change on scroll. 
Create a input[type=text] component which accepts regex to allow specific syntax */
const body = document.querySelector('body');
body.addEventListener('mousewheel', e => {
  const { target } = e;

  if (target.matches('input[type="number"]')) {
    e.target.blur();
  }
});

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);