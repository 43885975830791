export const getItCashLedger = async () => {
    return fetch('./itcashledger.json');
}

export const getFilingsStatus = async () => {
    return fetch('./filingsnapshot.json');
}

export const getGstinData = async () => {
    return import('./gstinCollection.json')
    // return fetch('./filingsnapshot.json');
}

export const getUserStatus = async () => {
    return import('./userStatus.json');
    // return fetch('./userStatus.json');
}