import create from "zustand"; /* Library to create store (global application state) like redux or recoil */
import { persist } from "zustand/middleware";

export const getGlobalStore = create(
  persist(
    (set) => ({
      loggedIn: false,
      b2bRecords: [],
      addB2bRecord: (record) =>
        set((state) => {
          let recordsCopy = [
            ...state.b2bRecords.filter((i) => i.id !== record.id),
            record,
          ];
          return {
            b2bRecords: recordsCopy,
          };
        }),
      deleteB2bInvoice: (invoiceId) => {
        set((state) => {
          return {
            b2bRecords: state.b2bRecords.filter((i) => i.id !== invoiceId)
          }
        })
      },
      b2cInvoices: [],
      addB2cInvoice: (invoice) => {
        set((state) => ({
          b2cInvoices: [
            ...state.b2cInvoices.filter((i) => i.id !== invoice.id),
            invoice,
          ],
        }));
      },
      deleteB2cInvoice: (invoiceId) => {
        set((state) => ({
          b2cInvoices: [...state.b2cInvoices.filter((i) => i.id !== invoiceId)],
        }));
      },
      gstr1Documents: {},
      setGstr1Documents: (docsObj) =>
        set((state) => ({ gstr1Documents: docsObj })),
      login: () =>
        set((state) => {
          sessionStorage.setItem("isLoggedIn", true);
          return { loggedIn: true };
        }),
      logout: () => set((state) => ({ loggedIn: false })),
      toggleSession: () => {
        set((st) => {
          return { loggedIn: st.loggedIn ? false : true };
        });
      },
      gstr1SummaryGenerated: false,
      setGstr1SummaryGenerated: (status = false) => {
        set((state) => {
          return { gstr1SummaryGenerated: status };
        });
      },
      user: {},
      updateUser: (userData) =>
        set((state) => {
          return { user: userData };
        }),
      gstr1Acknowledged: false,
      setGstr1Acknowledged: (isAcknowledged) => {
        set((state) => {
          return { gstr1Acknowledged: isAcknowledged };
        });
      },
      gstr1Submitted: false,
      setGstr1Submitted: (isSubmitted) => {
        set((state) => {
          return { gstr1Submitted: isSubmitted };
        });
      },
      isGstr1Filed: false,
      setGstr1Filed: (isGstr1Filed) => {
        set((state) => {
          return { isGstr1Filed };
        });
      },
      gstr1TaxItems: {},
      setGstr1TaxItems: (gstr1) => {
        set((state) => {
          let taxValues = {
            gstr1IntTax: 0,
            gstr1CenTax: 0,
            gstr1StaTax: 0,
            gstr1TaxableValue: 0,
            gstr1Cess: 0,
            interStateSupTaxableValue: 0
          };

          state.b2bRecords.map((record) => {
            taxValues.gstr1IntTax += record.totalIntegratedTax;
            taxValues.gstr1StaTax += record.totalStateTax;
            taxValues.gstr1CenTax += record.totalCentralTax;
            taxValues.gstr1TaxableValue += record.totalTaxableValue;
            taxValues.interStateSupTaxableValue += record.supplyType === 'Inter-State' ? record.totalTaxableValue : 0;
          });

          state.b2cInvoices.map((record) => {
            taxValues.gstr1IntTax += record.supplyType === 'Inter-State' ? record.totalTax : 0;
            taxValues.gstr1StaTax += record.supplyType === 'Intra-State' ? record.totalTax / 2 : 0;
            taxValues.gstr1CenTax += record.supplyType === 'Intra-State' ? record.totalTax / 2 : 0;
            taxValues.gstr1TaxableValue += Number(record.taxableValue);
            taxValues.interStateSupTaxableValue += record.supplyType === 'Inter-State' ? Number(record.taxableValue) : 0;
          });

          return { gstr1TaxItems: taxValues };
        });
      },
      nilReturn: '',
      setNilReturn: (nilReturn) => {
        set((state) => { return { nilReturn } });
      },
      itcItems: {},
      setItcItems: (itc) => {
        set((state) => {
          return { itcItems: itc };
        });
      },
      isGstr3bFiled: false,
      setGstr3bFiled: (isGstr3bFiled) => {
        set((state) => {
          return { isGstr3bFiled };
        });
      },
      isTaxPaid: false,
      setTaxPaidStatus: (isTaxPaid) => {
        set((state) => {
          return { isTaxPaid };
        });
      },
      taxPaymentDetails: {},
      setTaxPaymentDetails: (taxPaidObj) => {
        set((state) => {
          return { taxPaymentDetails: taxPaidObj };
        });
      },
      cashLedgerBalance: {},
      setCashLedgerBalance: (cashLedger) => {
        set((state) => {
          return { cashLedgerBalance : cashLedger};
        });
      }
    }),
    {
      name: "gst-simulator", // name of item in the storage (must be unique)
      getStorage: () => sessionStorage, // (optional) by default the 'localStorage' is used
    }
  )
);

getGlobalStore.subscribe(console.log);
